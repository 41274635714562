// import './App.css';
// // import SignIn from './Components/Auth/auth';
// import MainBoard from './Components/Dashboard/mainboard';
// import { HashRouter as Router, Route, Routes } from "react-router-dom";
// import Home from './Components/Dashboard/home';
// import Intgration from './Components/Dashboard/Intgration';
// import Profile from './Components/Dashboard/profile';
// import Accessmanager from './Components/Dashboard/manager';
// import Quickstart from './Components/Dashboard/quickstart';
// import Payment from './Components/Dashboard/payment';
// import Projects from './Components/Dashboard/projects';

// function App() {
//   return (
//     <>
//       <Router>
//         <Routes>
//           <Route path='/adminBoard' element={<MainBoard />}>
//             <Route index element={<Home />} />
//             <Route path='Integrations' element={<Intgration />} />
//             <Route path='Projects' element={<Projects />} />
//             <Route path='Payment' element={<Payment />} />
//             <Route path='Quickstart' element={<Quickstart />} />
//             <Route path='Accessmanager' element={<Accessmanager />} />
//             <Route path='Profile' element={<Profile />} />
//           </Route>
//         </Routes>
//       </Router>

//     </>

//   );
// }

// export default App;

import React from "react";
import Projects from './Components/Dashboard/projects';

function App() {
  return (
    <div className="App">
      <Projects />
    </div>
  );
}

export default App;