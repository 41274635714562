// import React, { useState } from "react";
// import "./project.css";
// import * as XLSX from "xlsx";

// const requiredHeaders = ["Sr No.", "Name", "Size", "Recipe items", "Price"];

// const Projects = () => {
//   const [data, setData] = useState([]);
//   const [showButton, setShowButton] = useState(true);
//   const [isEditable, setIsEditable] = useState(false);
//   const [error, setError] = useState("");
//   const [uploadMessage, setUploadMessage] = useState(
//     "Please Upload your menu sheet here..!"
//   );

//   const handleFileUpload = (e) => {
//     const file = e.target.files[0];
//     const reader = new FileReader();

//     reader.onload = (event) => {
//       const binaryStr = event.target.result;
//       const workbook = XLSX.read(binaryStr, { type: "binary" });
//       const sheetName = workbook.SheetNames[0];
//       const sheet = workbook.Sheets[sheetName];
//       const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

//       // Validate header
//       const headers = parsedData[0];
//       const isValid = requiredHeaders.every(
//         (header, index) => headers[index] === header
//       );

//       if (isValid) {
//         setData(parsedData);
//         setShowButton(false);
//         setUploadMessage("Your menu is here");
//         setError("");
//       } else {
//         setError("Please upload a sheet with the proper format.");
//       }
//     };

//     reader.readAsBinaryString(file);
//   };

//   const handleEdit = () => {
//     setIsEditable(true);
//   };

//   const handleSave = () => {
//     setIsEditable(false);
//     // Logic to save the data can be added here if needed
//   };

//   const handleChange = (e, rowIndex, colIndex) => {
//     const newData = [...data];
//     newData[rowIndex][colIndex] = e.target.value;
//     setData(newData);
//   };

//   return (
//     <>
//       <div className="project-container">
//         <div className="marketing-msg">
//           <div className="news-message">
//             <h2 className="marketMsg">
//               {" "}
//               I have a great news for you..... We’ve recently launched our large
//               Parantha Pizza at 50% discount!
//             </h2>
//           </div>
//           <button className="editMessage">Edit</button>
//           <button className="saveMessage">Save</button>
//         </div>

//         <div className="excel">
//           <h2>{uploadMessage}</h2>
//           {showButton && (
//             <div>
//               <div className="file-input">
//                 <input
//                   type="file"
//                   accept=".xlsx, .xls"
//                   onChange={handleFileUpload}
//                   style={{ marginBottom: "20px" }}
//                 />
//                 <span class="button">Choose</span>
//                 <span class="label" data-js-label>
//                   No file selected
//                 </span>
//               </div>

//               {error && <p style={{ color: "red" }}>{error}</p>}
//             </div>
//           )}
//           {data.length > 0 && (
//             <div>
//               <button
//                 className="beautiful-button"
//                 onClick={handleEdit}
//                 disabled={isEditable}
//               >
//                 Edit
//               </button>
//               <button
//                 className="beautiful-button"
//                 onClick={handleSave}
//                 disabled={!isEditable}
//               >
//                 Save
//               </button>
//               <table border="1" style={{ marginTop: "20px" }}>
//                 <thead>
//                   <tr>
//                     {data[0].map((col, index) => (
//                       <th key={index}>{col}</th>
//                     ))}
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {data.slice(1).map((row, rowIndex) => (
//                     <tr key={rowIndex}>
//                       {row.map((cell, cellIndex) => (
//                         <td key={cellIndex}>
//                           {isEditable ? (
//                             <input
//                               type="text"
//                               value={cell}
//                               onChange={(e) =>
//                                 handleChange(e, rowIndex + 1, cellIndex)
//                               }
//                             />
//                           ) : (
//                             cell
//                           )}
//                         </td>
//                       ))}
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Projects;

// import React, { useState, useEffect } from "react";
// import "./project.css";
// import * as XLSX from "xlsx";

// const requiredHeaders = ["Sr No.", "Name", "Size", "Recipe items", "Price"];

// const Projects = () => {
//   const [data, setData] = useState([]);
//   const [showButton, setShowButton] = useState(true);
//   const [isEditable, setIsEditable] = useState(false);
//   const [error, setError] = useState("");
//   const [uploadMessage, setUploadMessage] = useState(
//     "Please Upload your menu sheet here..!"
//   );
//   const [marketingMsg, setMarketingMsg] = useState("");
//   const [isEditingMsg, setIsEditingMsg] = useState(false);

//   // Load marketing message from localStorage on component mount
//   useEffect(() => {
//     const savedMsg = localStorage.getItem("marketingMessage");
//     if (savedMsg) {
//       setMarketingMsg(savedMsg);
//     } else {
//       setMarketingMsg("I have a great news for you..... We’ve recently launched our large Parantha Pizza at 50% discount!");
//     }
//   }, []);

//   const handleFileUpload = (e) => {
//     const file = e.target.files[0];
//     const reader = new FileReader();

//     reader.onload = (event) => {
//       const binaryStr = event.target.result;
//       const workbook = XLSX.read(binaryStr, { type: "binary" });
//       const sheetName = workbook.SheetNames[0];
//       const sheet = workbook.Sheets[sheetName];
//       const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

//       // Validate header
//       const headers = parsedData[0];
//       const isValid = requiredHeaders.every(
//         (header, index) => headers[index] === header
//       );

//       if (isValid) {
//         setData(parsedData);
//         setShowButton(false);
//         setUploadMessage("Your menu is here");
//         setError("");
//       } else {
//         setError("Please upload a sheet with the proper format.");
//       }
//     };

//     reader.readAsBinaryString(file);
//   };

//   const handleEdit = () => {
//     setIsEditable(true);
//   };

//   const handleSave = () => {
//     setIsEditable(false);
//     // Logic to save the data can be added here if needed
//   };

//   const handleChange = (e, rowIndex, colIndex) => {
//     const newData = [...data];
//     newData[rowIndex][colIndex] = e.target.value;
//     setData(newData);
//   };

//   const handleEditMarketingMsg = () => {
//     setIsEditingMsg(true);
//   };

//   const handleSaveMarketingMsg = () => {
//     setIsEditingMsg(false);
//     localStorage.setItem("marketingMessage", marketingMsg);
//   };

//   const handleMarketingMsgChange = (e) => {
//     setMarketingMsg(e.target.value);
//   };

//   return (
//     <div className="project-container">
//       <div className="marketing-msg">
//         <div className="news-message">
//           {isEditingMsg ? (
//             <div>
//               <textarea
//                 value={marketingMsg}
//                 onChange={handleMarketingMsgChange}
//                 rows="4"
//                 cols="50"
//               />
//               <button className="beautiful-button" onClick={handleSaveMarketingMsg}>
//                 Save Messages
//               </button>
//             </div>
//           ) : (
//             <div>
//               <h2 className="marketMsg">{marketingMsg}</h2>
//               <button className="beautiful-button" onClick={handleEditMarketingMsg}>
//                 Edit
//               </button>
//             </div>
//           )}
//         </div>
//       </div>

//       <div className="excel">
//         <h2>{uploadMessage}</h2>
//         {showButton && (
//           <div>
//             <div className="file-input">
//               <input
//                 type="file"
//                 accept=".xlsx, .xls"
//                 onChange={handleFileUpload}
//                 style={{ marginBottom: "20px" }}
//               />
//               <span className="button">Choose</span>
//               <span className="label" data-js-label>
//                 No file selected
//               </span>
//             </div>

//             {error && <p style={{ color: "red" }}>{error}</p>}
//           </div>
//         )}
//         {data.length > 0 && (
//           <div>
//             <button
//               className="beautiful-button"
//               onClick={handleEdit}
//               disabled={isEditable}
//             >
//               Edit
//             </button>
//             <button
//               className="beautiful-button"
//               onClick={handleSave}
//               disabled={!isEditable}
//             >
//               Save
//             </button>
//             <table border="1" style={{ marginTop: "20px" }}>
//               <thead>
//                 <tr>
//                   {data[0].map((col, index) => (
//                     <th key={index}>{col}</th>
//                   ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {data.slice(1).map((row, rowIndex) => (
//                   <tr key={rowIndex}>
//                     {row.map((cell, cellIndex) => (
//                       <td key={cellIndex}>
//                         {isEditable ? (
//                           <input
//                             type="text"
//                             value={cell}
//                             onChange={(e) =>
//                               handleChange(e, rowIndex + 1, cellIndex)
//                             }
//                           />
//                         ) : (
//                           cell
//                         )}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Projects;



// import React, { useState, useEffect } from "react";
// import "./project.css";
// import * as XLSX from "xlsx";

// const requiredHeaders = ["Sr No.", "Name", "Size", "Recipe items", "Price"];

// const Projects = () => {
//   const [data, setData] = useState([]);
//   const [showButton, setShowButton] = useState(true);
//   const [isEditable, setIsEditable] = useState(false);
//   const [error, setError] = useState("");
//   const [uploadMessage, setUploadMessage] = useState(
//     "Please Upload your menu sheet here..!"
//   );
//   const [marketingMsg, setMarketingMsg] = useState("");
//   const [isEditingMsg, setIsEditingMsg] = useState(false);

//   useEffect(() => {
//     // Fetch the current marketing message from the API
//     const fetchMarketingMessage = async () => {
//       try {
//         const response = await fetch('https://leadapi.ivoz.ai/chicago-pizza/initial-greetings', {
//           method: 'GET',
//           headers: {
//             'Accept': 'application/json',
//           },
//         });
//         const result = await response.json();
//         if (response.ok) {
//           setMarketingMsg(result.message || "No message available.");
//         } else {
//           console.error('Failed to fetch marketing message.');
//         }
//       } catch (error) {
//         console.error('Error fetching marketing message:', error);
//       }
//     };

//     fetchMarketingMessage();
//   }, []);

//   const handleFileUpload = async (e) => {
//     const file = e.target.files[0];
//     if (!file) return;

//     // Parse the file locally
//     const reader = new FileReader();

//     reader.onload = (event) => {
//       const binaryStr = event.target.result;
//       const workbook = XLSX.read(binaryStr, { type: "binary" });
//       const sheetName = workbook.SheetNames[0];
//       const sheet = workbook.Sheets[sheetName];
//       const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

//       // Check headers
//       const headers = parsedData[0];
//       const isValid = requiredHeaders.every(
//         (header, index) => headers[index] === header
//       );

//       if (isValid) {
//         setData(parsedData);
//         setShowButton(false);
//         setUploadMessage("Your menu is here");
//         setError("");

//         // Prepare FormData for API upload
//         const formData = new FormData();
//         formData.append("file", file);

//         // Upload the file to the API
//         fetch("https://leadapi.ivoz.ai/chicago-pizza/upload-kb", {
//           method: "POST",
//           headers: {
//             accept: "application/json",
//           },
//           body: formData,
//         })
//           .then(response => response.json())
//           .then(result => {
//             if (result.status) {
//               console.log(result.message); // or display this message in UI
//             } else {
//               console.error('Failed to upload the file to the server');
//             }
//           })
//           .catch(error => {
//             console.error("Error uploading file:", error);
//           });

//       } else {
//         setError("Please upload a sheet with the proper format.");
//       }
//     };

//     reader.readAsBinaryString(file);
//   };

//   const handleEdit = () => {
//     setIsEditable(true);
//   };

//   const handleSave = () => {
//     setIsEditable(false);
//   };

//   const handleChange = (e, rowIndex, colIndex) => {
//     const newData = [...data];
//     newData[rowIndex][colIndex] = e.target.value;
//     setData(newData);
//   };

//   const handleEditMarketingMsg = () => {
//     setIsEditingMsg(true);
//   };

//   const handleSaveMarketingMsg = async () => {
//     setIsEditingMsg(false);

//     // API call to update the marketing message
//     try {
//       const response = await fetch(
//         `https://leadapi.ivoz.ai/chicago-pizza/initial-greetings?greeting_text=${encodeURIComponent(marketingMsg)}`,
//         {
//           method: 'POST',
//           headers: {
//             'Accept': 'application/json',
//           },
//         }
//       );
//       const result = await response.json();
//       if (response.ok) {
//         alert('Greeting text updated successfully');
//       } else {
//         alert('Failed to update greeting text.');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       alert('An error occurred while updating the greeting text.');
//     }
//   };

//   const handleMarketingMsgChange = (e) => {
//     setMarketingMsg(e.target.value);
//   };

//   return (
//     <div className="project-container">
//       <div className="marketing-msg">
//         <div className="news-message">
//           {isEditingMsg ? (
//             <div>
//               <textarea
//                 value={marketingMsg}
//                 onChange={handleMarketingMsgChange}
//                 rows="4"
//                 cols="50"
//               />
//               <button className="beautiful-button" onClick={handleSaveMarketingMsg}>
//                 Save Messages
//               </button>
//             </div>
//           ) : (
//             <div>
//               <h2 className="marketMsg">{marketingMsg}</h2>
//               <button className="beautiful-button" onClick={handleEditMarketingMsg}>
//                 Edit
//               </button>
//             </div>
//           )}
//         </div>
//       </div>

//       <div className="excel">
//         <h2>{uploadMessage}</h2>
//         {showButton && (
//           <div>
//             <div className="file-input">
//               <input
//                 type="file"
//                 accept=".xlsx, .xls"
//                 onChange={handleFileUpload}
//                 style={{ marginBottom: "20px" }}
//               />
//               <span className="button">Choose</span>
//               <span className="label" data-js-label>
//                 No file selected
//               </span>
//             </div>

//             {error && <p style={{ color: "red" }}>{error}</p>}
//           </div>
//         )}
//         {data.length > 0 && (
//           <div>
//             <button
//               className="beautiful-button"
//               onClick={handleEdit}
//               disabled={isEditable}
//             >
//               Edit
//             </button>
//             <button
//               className="beautiful-button"
//               onClick={handleSave}
//               disabled={!isEditable}
//             >
//               Save
//             </button>
//             <table border="1" style={{ marginTop: "20px" }}>
//               <thead>
//                 <tr>
//                   {data[0].map((col, index) => (
//                     <th key={index}>{col}</th>
//                   ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {data.slice(1).map((row, rowIndex) => (
//                   <tr key={rowIndex}>
//                     {row.map((cell, cellIndex) => (
//                       <td key={cellIndex}>
//                         {isEditable ? (
//                           <input
//                             type="text"
//                             value={cell}
//                             onChange={(e) =>
//                               handleChange(e, rowIndex + 1, cellIndex)
//                             }
//                           />
//                         ) : (
//                           cell
//                         )}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         )}
//       </div>

//       {/* Button for showing the required format */}
//       <div className="format-button">
//         <div className="format-btn-inner">
//           <button className="hover-button">?</button>
//           <div className="tooltip">
//             <table>
//               <thead>
//                 <tr>
//                   <th>Sr No.</th>
//                   <th>Name</th>
//                   <th>Size</th>
//                   <th>Recipe Item</th>
//                   <th>Price</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td>1</td>
//                   <td>Onion</td>
//                   <td>Large</td>
//                   <td>Pizza</td>
//                   <td>$2</td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Projects;


import React, { useState, useEffect } from "react";
import "./project.css";

const Projects = () => {
  const [showButton, setShowButton] = useState(true);
  const [error, setError] = useState("");
  const [uploadMessage, setUploadMessage] = useState(
    "Please Upload your menu sheet here..! It must be PDF only"
  );
  const [marketingMsg, setMarketingMsg] = useState("");
  const [isEditingMsg, setIsEditingMsg] = useState(false);

  useEffect(() => {
    // Fetch the current marketing message from the API
    const fetchMarketingMessage = async () => {
      try {
        const response = await fetch('https://leadapi.ivoz.ai/chicago-pizza/initial-greetings', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
          },
        });
        const result = await response.json();
        if (response.ok) {
          setMarketingMsg(result.message || "I have a great news for you..... We’ve recently launched our large Parantha Pizza at 50% discount!");
        } else {
          console.error('Failed to fetch marketing message.');
        }
      } catch (error) {
        console.error('Error fetching marketing message:', error);
      }
    };

    fetchMarketingMessage();
  }, []);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.type !== "application/pdf") {
      setError("Please upload a PDF file.");
      return;
    }

    setError("");  // Clear any previous errors
    setUploadMessage("Your menu is being uploaded...");

    // Prepare FormData for API upload
    const formData = new FormData();
    formData.append("file", file);

    // Upload the file to the API
    try {
      const response = await fetch("https://leadapi.ivoz.ai/chicago-pizza/upload-kb", {
        method: "POST",
        headers: {
          accept: "application/json",
        },
        body: formData,
      });
      const result = await response.json();
      if (result.status) {
        setUploadMessage("Your menu has been successfully uploaded.");
        console.log(result.message);
      } else {
        setUploadMessage("Failed to upload the file to the server.");
        console.error('Failed to upload the file to the server');
      }
    } catch (error) {
      setUploadMessage("Error uploading file.");
      console.error("Error uploading file:", error);
    }

    setShowButton(false);
  };

  const handleEditMarketingMsg = () => {
    setIsEditingMsg(true);
  };

  const handleSaveMarketingMsg = async () => {
    setIsEditingMsg(false);
    localStorage.setItem("marketingMessage", marketingMsg);

    // API call to update the marketing message
    try {
      const response = await fetch(
        `https://leadapi.ivoz.ai/chicago-pizza/initial-greetings?greeting_text=${encodeURIComponent(marketingMsg)}`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
          },
        }
      );
      const result = await response.json();
      if (response.ok) {
        alert(result.message);
      } else {
        alert('Failed to update greeting text.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while updating the greeting text.');
    }
  };

  const handleMarketingMsgChange = (e) => {
    setMarketingMsg(e.target.value);
  };

  return (
    <div className="project-container">
      <div className="marketing-msg">
        <div className="news-message">
          {isEditingMsg ? (
            <div>
              <textarea
                value={marketingMsg}
                onChange={handleMarketingMsgChange}
                rows="4"
                cols="50"
              />
              <button className="beautiful-button" onClick={handleSaveMarketingMsg}>
                Save Messages
              </button>
            </div>
          ) : (
            <div>
              <h2 className="marketMsg">{marketingMsg}</h2>
              <button className="beautiful-button" onClick={handleEditMarketingMsg}>
                Edit
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="excel">
        <h2>{uploadMessage}</h2>
        {showButton && (
          <div>
            <div className="file-input">
              <input
                type="file"
                accept="application/pdf"
                onChange={handleFileUpload}
                style={{ marginBottom: "20px" }}
              />
              <span className="button">Choose</span>
              <span className="label" data-js-label>
                No file selected
              </span>
            </div>

            {error && <p style={{ color: "red" }}>{error}</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;



